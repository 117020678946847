.editor-main-container {
    margin-top: 40px;
    width: 60%;
    margin-left: 20vw;
    margin-right: 20vw;
}


.title-blog {
    text-align: center;
}

.editor {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px;
}