.team-callout-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .team-callout-wrap {
        flex-wrap: wrap;
    }
    
}

.team-callout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    padding: 30px;
    gap: 20px;
    box-sizing: border-box;
}

.team-intro-wrap {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.team-intro-wrap h3, p {
    margin: 0;
    color: gray;
    font-size: 16px;
}
.team-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.team-image {
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
}

.team-content-wrap p {
    color: gray;
    font-size: 12px;
}

.linkedin-team {
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    width: fit-content;
    padding: 0.5rem;
}