/* Navbar.css */

/* General Styles */

.navbar-main {
  display: block;
  height: 60px;
  width: 100%;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;  
  align-items: center;
  background-color: #fff;
  color: black; 
  padding: 0px 0px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 3px -3px;
  border-bottom: 1px solid rgb(211, 211, 211);
  /* position: fixed; */
  top: 0;
  z-index: 10000;
  position: relative;
}


.logo {
  height: 60px;
  position: absolute;
  top: 0;
}
.navbar_links {
  flex: 1;
    display: flex;
    justify-content: center;
}
.nav-links {
  display: flex;
  list-style: none;
  margin: 20px 0; 
  padding: 0;
  justify-content: center; /* Center the links horizontally */
}

.nav-links li {
  margin-right: 40px;
}

.nav-links li:last-child {
  margin-right: 0; /* Remove margin from the last link */
}

.nav-links li a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #ccc; /* Change color on hover */
}


.icon {
  display: flex;
  align-items: center;
  gap: 30px;
}

.contribute-btn {
  background-color: #b5218b;
  border-radius: 5px;
  box-shadow: 0 10px 10px #d0cfd0;
  padding: .667em 1em;
  font-size: 14px;
  color: white;
  cursor: pointer;
  border: none;
  margin-right: 30px;
  transition: transform 0.3s ease;
}
.login-btn {
  width: 69px;
  text-align: center;
  text-decoration: none;
}

.contribute-btn:hover {
  background-color: #003366;
  transform: scale(1.1);
}