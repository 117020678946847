.author-profile-container {
    margin-left: 10vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
}


.no-blogs {
    margin: 0;
    padding: 0;
    color: red;
    font-size: 36px;
    margin-top: 50px;
}