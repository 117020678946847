.posts-container-wrap {
    display: flex;
    margin-bottom: 50px;
}

.posts-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 60px;
}

.home_posts {
    box-sizing: border-box;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .posts-wrap {
        grid-template-columns: 1fr;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-left: 20px;
        padding-right: 20px;
    }
    
}
