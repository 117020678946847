.main-container-card {
    color: black;
}
.content {
    font-family: Merriweather;
}


.blog-title {
    margin-bottom: 0;
    font-style: bold;
    text-decoration: none;
    color: black;
}

.blog-title:hover {
    color: blueviolet;
}

.credits {
    display : flex;
    color: #757575;
    font-size: 0.770rem;
    margin-bottom: 1em;
}

.vl {
    border-left: 0.5px solid grey;
    height : 18px;
    margin: 0 5px;
}

.meta-body-postcard {
    color: grey;
}

