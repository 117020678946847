

.contributor-callout-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

@media (min-width: 640px) {
    .contributor-callout-wrap {
    grid-template-columns: repeat(3, 1fr);
}
}

@media (min-width: 768px) {
.contributor-callout-wrap {
    grid-template-columns: repeat(4, 1fr);
}
}

.contributor {
    display: flex;
    flex-direction: row;;
    gap: 20px;
    align-items: center;
}

.contributor-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.user-icon {
    font-size: 25px;
}