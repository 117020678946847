body {
  font-family: "FS Albert Extra Bold", Helvetica, Arial, sans-serif;
  color: black;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  overflow-x: hidden;
}
p {
  font-family: Merriweather, Verdana, Arial, serif;
} 