
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

/* Form Box */
.form-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.form-box h1 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: blue;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 1rem;
}

input {
  width: 90%;
  padding: 0.8rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  size: 50px;
}

input:focus {
  outline: none;
  border-color: blue;
}

/* Button */
button[type="submit"] {
  padding: 0.8rem 1.5rem;
  background-color: blue;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

button[type="submit"]:hover {
  background-color: #5a52d9;
}

/* Login Link */
.login-link {
  margin-top: 1.5rem;
  text-align: center;
}

.login-link a {
  color: #6c63ff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 480px) {
  .form-box {
    padding: 1.5rem;
  }
}
