

.title-block-wrap {
    margin-left: 10vw;
    margin-top: 4vw;
    margin-bottom: 4vw; 
}

.page-title-h1 {
    font-size : 3.5em;  
    margin: 0;
    width: 51%;
    color: #e31e39;
    text-wrap: wrap;
}

.second-location {
    font-weight: bold;
}


