.body {
    white-space: pre-wrap;
}

.posts-wrap {
    margin-left: 10vw;
    margin-right: 25vw;
}

.post-main-container {
    padding: 30px 20vw;
    margin-top: 25px;   
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .post-main-container {
        padding: 30px 5vw;
    }
    
}

.breadcrumb {
    color: #757575;
    font-size: 0.770rem;
    text-decoration: none;
}
.location {
    color: #757575;
    text-decoration: none;
}

.location:hover {
    text-decoration: underline;
}

.title {
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-body {
    padding: 30px 5vw;
    height: 100%;
    display: inline-block;
    width: fit-content; /* Ensures the width fits the content */
    height: fit-content; /* Ensures the height fits the content */
}

.blog-body p {
    color: black;
}
    
.post-comment-wrap {
    margin-top: 40px;
    border-top: 1px solid #757575;
    padding-top: 40px;
}