.copyright {
	text-align: center;
	color: gray;
	font-size: 0.7em;
}

.footer__redes {
	display: flex;
	justify-content: center;
}

.footer__icon {
	margin: 1rem;
	padding: 0.5rem;
	font-size: 1.4em;
	cursor: pointer;
}