/* Container */
.container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #f5f5f5;
 }
 
 /* Form Box */
 .form-box {
   background-color: #fff;
   padding: 2rem;
   border-radius: 8px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   max-width: 400px;
   width: 100%;
 }
 
 /* Login Header */
 .login__header {
   font-size: 2rem;
   font-weight: bold;
   margin-bottom: 1rem;
   text-align: center;
   color: blue;
 }
 
 /* Input Fields */
 .input-field {
   position: relative;
   margin-bottom: 1rem;
 }
 
 .input-field input {
   padding: 0.8rem 1rem;
   border: 1px solid #ccc;
   border-radius: 4px;
   width: 90%;
   font-size: 1rem;
 }
 
 .input-field input:focus {
   outline: none;
   border-color: blue;
 }
 
 /* Password Eye Icon */
 .input-field svg {
   position: absolute;
   top: 50%;
   right: 1rem;
   transform: translateY(-50%);
   font-size: 1.2rem;
   color: blue;
   cursor: pointer;
 }
 
 /* Form Links */
 .form-link {
   margin-bottom: 1rem;
   text-align: center;
 }
 
 .form-link a {
   color: blue;
   text-decoration: none;
  
 }
 
 .form-link a:hover {
   text-decoration: underline;
 }
 
 /* Button */
 .button-field button {
   padding: 0.8rem 1.5rem;
   background-color: blue;
   color: #fff;
   border: none;
   border-radius: 4px;
   font-size: 1rem;
   cursor: pointer;
   transition: background-color 0.3s ease;
   width: 100%;
 }
 
 .button-field button:hover {
   background-color: blue;
 }
 
 /* Responsive Styles */
 @media (max-width: 480px) {
   .form-box {
     padding: 1.5rem;
   }
 }
 