.featured-block-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
    padding: 0 40px;
}
.featured-block-wrap > div {
    width: 50%; /* Each child takes 50% of the width */
    box-sizing: border-box; /* Ensure padding/border doesn't affect width */
  } 
  
  /* Stack the first two children vertically */
  .featured-block-wrap > div:nth-child(1),
  .featured-block-wrap > div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }

.featured-inner-block-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .featured-block-wrap {
        padding: 0 0;
        flex-direction: column;
    }
    .featured-block-wrap > div {
        width: 100%; /* Each child takes 50% of the width */
        box-sizing: border-box; /* Ensure padding/border doesn't affect width */
      } 

}

.featured-content-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.featured-title-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.featured-title-wrap h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0;
    display: block;
}



.blog-pic {
    width: 100%;
    height: 100%;
}
.blog-img {
    height: auto;
    width: 100%;
}

.featured-post-title {
    text-decoration: none;
    color: black
}
.featured-post-title:hover {
    color: blueviolet;
}